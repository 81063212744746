import axios from 'axios';
import { useQuery } from '@tanstack/react-query';
import { MovieDetailed } from '../../../shared';

export type MovieDetailedInputParams = {
  imdbId: string | null;
};

export interface MovieDetailedOutputParams {
  movieDetailed: MovieDetailed;
}

const sendGetMovieDetailed = async (imdbId: string | null): Promise<MovieDetailedOutputParams | null> => {
  try {
    if (imdbId === null) {
      return null;
    }
    const { data } = await axios.get(`api/movie/${imdbId}`);
    return data as MovieDetailedOutputParams;
  } catch (e) {
    return null;
  }
};

export const useMovieDetailed = ({ imdbId }: MovieDetailedInputParams) => {
  return useQuery({
    queryKey: ['GET_MOVIE_DETAILED', imdbId],
    queryFn: () => sendGetMovieDetailed(imdbId),
  });
};
