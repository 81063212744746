import React, { useState } from 'react';
import { TableHeaderRow, TableRow } from '../../../features';

export type Column = {
  key: string;
  label: string;
};

type Props = {
  rows: any[];
  columns: Column[];
  className?: string;
  onRowClick?: (row: any) => void;
};

export type SortDetails = {
  column: Column;
  isDescending: boolean;
};

export const Table = ({ rows, columns, className, onRowClick }: Props) => {
  const [ sortDetails, setSortDetails ] = useState<SortDetails>({column: columns[0], isDescending: false} as SortDetails);

  let sortedRows: object[] = [];

  if (rows?.length) {
    sortedRows = [...rows].sort((a, b) => {
      if (a[sortDetails.column.key] < b[sortDetails.column.key]) {
        return sortDetails.isDescending ? 1 : -1;
      }
      if (a[sortDetails.column.key] > b[sortDetails.column.key]) {
        return sortDetails.isDescending ? -1 : 1;
      }
      return 0;
    });
  }

  const handleSortArrowClick = (column: Column, isDescending: boolean) => {
    setSortDetails({column, isDescending});
  };

  return (
    <ul className={`table w-full border-collapse relative ${className}`}>
      {sortedRows?.length ? (<>
        <TableHeaderRow columns={columns} sortColumn={sortDetails.column} isDescending={sortDetails.isDescending} onSortArrowClick={handleSortArrowClick} />
        {sortedRows?.map((row) => (
          <TableRow row={row} columns={columns} onClick={() => onRowClick?.(row)}/>
        ))}
      </>) : null}
    </ul>
  );
};