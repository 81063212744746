import axios from 'axios';
import { useMutation } from '@tanstack/react-query';
import { MovieOverview } from '../../../shared';

export type MoviesSearchInputParams = {
  searchText: string;
  pageNumber: number;
  onFinished?: (output: MoviesSearchOutputParams) => void;
};

export interface MoviesSearchOutputParams {
  movieOverviews: MovieOverview[];
  moviesTotalCount: number;
}

const sendSearchMovies = async (movieTitleFragment: string, pageNumber: number): Promise<MoviesSearchOutputParams> => {
  try {
    if (['', null, undefined].includes(movieTitleFragment)) {
      throw new Error();
    }
    const response = await axios.post(`api/find-movie-overviews-by-title-fragment`, {movieTitleFragment, pageNumber});
    return response?.data as MoviesSearchOutputParams;
  } catch (e) {
    return {
      movieOverviews: [],
      moviesTotalCount: 0
    } as MoviesSearchOutputParams;
  }
};

export const useMoviesSearchMutation = ({searchText, pageNumber, onFinished}: MoviesSearchInputParams) => {
  return useMutation({
    mutationFn: () => sendSearchMovies(searchText, pageNumber),
    onSuccess: (output: MoviesSearchOutputParams) => {
      onFinished?.(output);
    },
  });
};
