import { CaretDownIcon, SearchIcon, TextInput, TextInputRef } from '../../../shared';
import React, { useRef, useState } from 'react';

export type Props = {
  onSearchTextChange?: (value: string) => void;
  className?: string;
};

export const RecallSearchTextInput = ({ onSearchTextChange, className }: Props) => {
  const [expanded, setExpanded] = useState(false);
  const textInputRef = useRef<TextInputRef>(null);
  const timeoutIdRef = useRef<NodeJS.Timeout>();
  const searchTextRef = useRef<string>();

  const [ historyItems, setHistoryItems ] = useState<string[]>(JSON.parse(localStorage.getItem('historyItems') as string) || []);


  const handleDropdownExpandClick = () => {
    setExpanded(expanded => !expanded);
  };

  const handleHistoryItemClick = (item: string) => {
    setExpanded(false);
    textInputRef.current?.setText(item);
  };

  const handleSearchTextChange = (text: string) => {
    if (timeoutIdRef.current) {
      clearTimeout(timeoutIdRef.current);
    }
    timeoutIdRef.current = setTimeout(() => {
      if (text !== searchTextRef.current) {
        searchTextRef.current = text;
        if (text?.length && !historyItems.includes(text)) {
          setHistoryItems((current: string[]) => {
            const next = [...current];
            next.unshift(text);
            if (next.length > 5) {
              next.pop();
            }
            localStorage.setItem('historyItems', JSON.stringify(next));
            return next;
          });
        }
        onSearchTextChange?.(text);
        setExpanded(false);
      }
    }, 700);
  };

  return (
    <section className={`flex flex-col relative ${className}`}>
      <span className='flex flex-row'>
        <TextInput ref={textInputRef} className='flex-grow flex-shrink' placeholder='Search' Icon={SearchIcon} onChange={handleSearchTextChange}/>
        {historyItems.length ?
          <button className='flex justify-center items-center border-2 border-l-0 border-gray-600 h-10 w-6 flex-shrink-0 flex-grow-0' onClick={handleDropdownExpandClick}>
            <CaretDownIcon className=''/>
          </button> : null
        }
      </span>
      {expanded && (
        <menu className='min-w-full max-w-full absolute top-full z-10'>
          {historyItems.map((item, index) => (
            <li
              key={index || 0}
              className={`cursor-pointer h-10 flex items-center px-5 ${index === 0 ? 'rounded-t-lg' : ''} ${index === historyItems.length - 1 ? 'rounded-b-lg' : ''} bg-theme-menu-item-background hover:bg-theme-menu-item-background-active`}
              onClick={() => handleHistoryItemClick(item)}
            >
              <div className="w-full max-w-full overflow-hidden text-nowrap text-ellipsis text-sm font-bold">
                {item}
              </div>
            </li>
          ))}
        </menu>
      )}
    </section>
  );
};