import { TableCell } from './TableCell';
import { Column } from '../../../widgets';
import React from 'react';

type Props = {
  row: any;
  columns: Column[];
  onClick: () => void;
};

export const TableRow = ({ row, columns, onClick }: Props) => {
  return (
    <li className="table-row h-8 max-h-8 sm:h-10 sm:max-h-10 border-t border-theme-background-800 border-b cursor-pointer hover:bg-gray-700" key={row[columns[0].key]} onClick={onClick}>
      {columns.map(column => (
        <TableCell className={`table-cell first:pl-4 last:pr-4`}>
          {row[column.key]}
        </TableCell>
      ))}
    </li>
  );
};