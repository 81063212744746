import React, { forwardRef, useImperativeHandle, useState } from 'react';
import { useMovieDetailed } from '..';
import { Spinner } from '../../../shared/ui/Spinner';

export type Props = {
  className?: string;
};

export type MovieDetailedRef = {
  setImdbId: (imdbId: string) => void;
};

export const MovieDetailed = forwardRef<MovieDetailedRef, Props>(({ className }: Props, ref) => {
  const [ imdbId, setImdbId ] = useState<string | null>(null);
  const { data = undefined } = useMovieDetailed({imdbId});
  const movieDetailed = data?.movieDetailed;

  useImperativeHandle(ref, () => ({
    setImdbId(imdbId: string) {
      setImdbId(imdbId);
    }
  }));

  return (<>
    <section className='flex flex-col sm:flex-row justify-start min-w-[85svw] sm:min-w-[60svw] lg:min-w-[50rem] max-w-[85svw] sm:max-w-[60svw] lg:max-w-[50rem] min-h-[31.25rem]'>
      <dl className='grid grid-cols-[auto,1fr] gap-4 order-2 sm:order-1'>
        <dt>Name:</dt>
        <dd>{movieDetailed?.title}</dd>
        <dt>Type:</dt>
        <dd>{movieDetailed?.type}</dd>
        <dt>Genre:</dt>
        <dd>{movieDetailed?.genre}</dd>
        <dt>Released:</dt>
        <dd>{movieDetailed?.released}</dd>
        <dt>Duration:</dt>
        <dd>{movieDetailed?.runtime}</dd>
        <dt>Director:</dt>
        <dd>{movieDetailed?.director}</dd>
        <dt>Writer:</dt>
        <dd>{movieDetailed?.writer}</dd>
        <dt>Actors:</dt>
        <dd>{movieDetailed?.actors}</dd>
        <dt>Plot:</dt>
        <dd>{movieDetailed?.plot}</dd>
      </dl>
      <img src={movieDetailed?.posterUrl} className='order-1 sm:order-2 min-w-[40%] max-w-[40%] float-right mb-auto pb-5' alt="movie poster"/>
    </section>
    {data === undefined && <Spinner/>}
  </>);
});