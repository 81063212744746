import React, { forwardRef, ReactNode, useEffect, useImperativeHandle, useRef } from 'react';
import { createPortal } from 'react-dom';
import { CloseButton } from '../../../shared';

export type Props = {
  title: string;
  children: ReactNode;
};

export type ModalRef = {
  open: () => void;
};

export const Modal = forwardRef<ModalRef, Props>(({ title, children }: Props, ref) => {
  const dialog = useRef<HTMLDialogElement>(null);

  useImperativeHandle(ref, () => ({
    open() {
      dialog.current?.showModal();
    }
  }));

  return createPortal((
    <dialog ref={dialog} className='z-0 bg-theme-background-600 overflow-hidden'>
      <form method='dialog' className='flex flex-col justify-stretch px-8 py-6'>
        <section className='flex flex-row flex-shrink-0 flex-grow-0 justify-between mb-8'>
          <h2 className='text-xl font-bold'>{title}</h2>
          <CloseButton/>
        </section>
        <section className='flex flex-shrink flex-grow overflow-x-hidden overflow-y-auto'>{children}</section>
      </form>
    </dialog>),
    document.getElementById('root') as HTMLElement
  );
});
