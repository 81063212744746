import React from 'react';

export type Props = {
  className?: string;
};

export const SiteHeader = ({ className }: Props) => (
  <header className={`flex flex-row h-7 sm:h-14 border-b border-zinc-700 items-center px-5 bg-theme-background-800 ${className}`}>
    <h1 className='text-sm sm:text-xl font-bold'>Movie Search App</h1>
  </header>
);