import React from 'react';

export const Spinner = () => (
  <section className="z-50 absolute top-0 left-0 flex flex-col items-center w-full h-full bg-black bg-opacity-70 animate-fadeIn">
    <svg viewBox="0 0 50 50" className="animate-rotate absolute top-1/2 left-1/2 -mt-8 -ml-8 w-14 h-14">
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="7" className="stroke-gray-600"/>
      <circle cx="25" cy="25" r="20" fill="none" strokeWidth="7" className="stroke-green-500 animate-dash"/>
    </svg>
    <span className="absolute top-1/2 mt-8 ml-2 text-lg font-bold">Loading...</span>
  </section>
);