import React, { FC, forwardRef, useImperativeHandle, useState } from 'react';

type Props = {
  placeholder?: string;
  value?: string | number;
  onChange?: (value: string) => void;
  Icon?: FC;
  className?: string;
};

export type TextInputRef = {
  setText: (text: string) => void;
};

export const TextInput = forwardRef<TextInputRef, Props>(({ placeholder, value, onChange, Icon, className }: Props, ref) => {
  const [text, setText] = useState(value);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setText(event.target.value);
    onChange?.(event.target.value);
  };

  useImperativeHandle(ref, () => ({
    setText(text: string) {
      setText(text);
      onChange?.(text);
    }
  }));

  return (
    <section className={`flex flex-row items-center gap-2 border-2 border-gray-600 px-3 py-1.5 ${className}`}>
      <input
        className="flex bg-transparent outline-none shadow-none flex-shrink min-w-0 flex-grow text-white text-base font-normal placeholder-gray-600"
        placeholder={placeholder}
        value={text}
        onChange={handleInputChange}
      />
      {Icon && <span className = 'flex-shrink-0 flex-grow-0'><Icon/></span>}
    </section>
  );
});