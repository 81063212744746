import React from 'react';
import { CloseIcon } from '../..';

type CloseButtonProps = {
  onClick?: () => void;
};

export const CloseButton = ({ onClick }: CloseButtonProps) => {
  return (
    <button onClick={onClick}>
      <CloseIcon />
    </button>
  );
};