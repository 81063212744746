import React, { useState } from 'react';

export type Props = {
  pagesCount: number;
  currentPage?: number;
  className?: string;
  onCurrentPageChange: (currentPage: number) => void;
};

export const Pager = ({pagesCount, currentPage, className, onCurrentPageChange}: Props) => {
  return (
    <ol className={`flex flex-row flex-wrap ${className}`}>
      {[...Array(Math.min(99, pagesCount)).keys()].map(pageNumber =>
        <li key={pageNumber} className='inline-flex min-w-6'>
          {(pageNumber + 1) !== currentPage ?
            <a href='#' className='w-full text-center hover:underline hover:underline-offset-4' onClick={() => onCurrentPageChange(pageNumber + 1)}>{pageNumber + 1}</a> :
            <span className='font-bold pointer-events-none select-none text-theme-active w-full text-center'>{pageNumber + 1}</span>
          }
        </li>
      )}
      {pagesCount > 99 && <li key={100} className='inline-flex min-w-6'>
        <span className='pointer-events-none select-none w-full text-center'>...</span>
      </li>}
    </ol>
  );
};