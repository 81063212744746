import React, { ReactNode } from 'react';

export type Props = {
  children?: ReactNode;
  className?: string;
};

export const TableCell = ({children, className}: Props) => (
  <li className={`table-cell align-middle text-left text-sm sm:text-base ${className}`}>
    {children}
  </li>
);