import React from 'react';
import { Column } from '../../../widgets';

type Props = {
  columns: Column[];
  sortColumn: Column;
  isDescending: boolean;
  onSortArrowClick: (column: Column, isDescending: boolean) => void;
  className?: string;
};

export const TableHeaderRow = ({columns, sortColumn, isDescending, onSortArrowClick, className}: Props) => {

  const handleClick = (newSortColumn: Column) => {
    let newIsDescending = false;
    if (newSortColumn === sortColumn) {
      newIsDescending = !isDescending;
    }
    onSortArrowClick(newSortColumn, newIsDescending);
  };

  return (
    <li className={`table-row border-t border-theme-background-800 h-7 sticky ${className}`}>
      {columns.map((column) => (
        <span
          key={column.key}
          // onClick={() => handleClick(column)}
          className={`table-cell align-middle text-left text-xs font-bold select-none first:pl-4 last:pr-4 text-gray-400 cursor-pointer relative /*${column === sortColumn ? 'text-theme-active' : 'text-gray-400'}*/`}
        >
          {column.label}
          <span
            className={`absolute top-[0.7rem] ml-1 w-0 h-0 border-l-[0.313rem] border-r-[0.313rem] border-transparent /*${column === sortColumn && isDescending ? 'border-t-[0.313rem] border-t-theme-active' : ''} ${column === sortColumn && !isDescending ? 'border-b-[0.313rem] border-b-theme-active' : ''}*/`}
          />
        </span>
      ))}
    </li>
  );

};