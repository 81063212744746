import React, { useEffect, useRef, useState } from 'react';
import { useMoviesSearchMutation } from '../';
import { MovieOverview, Pager } from '../../../shared';
import { Column, Modal, ModalRef, MovieDetailed, MovieDetailedRef, RecallSearchTextInput, Table } from '../../../widgets';
import { Spinner } from '../../../shared/ui/Spinner';

export type Props = {
  className?: string;
};

const MoviesTableColumns: Column[] = [
  {key: 'title', label: 'Title'},
  {key: 'year', label: 'Year of Release'},
  {key: 'type', label: 'Type'},
]

export const MoviesSearchPage = ({ className }: Props) => {
  const [ searchText, setSearchText ] = useState<string>('');
  const [ currentPage, setCurrentPage ] = useState<number>(1);
  const [ movieOverviews, setMovieOverviews ] = useState<MovieOverview[] | null>(null);
  const [ pageCount, setPageCount ] = useState<number>(0);
  const modal = useRef<ModalRef>(null);
  const movieDetailedRef = useRef<MovieDetailedRef>(null);

  const moviesSearchMutation = useMoviesSearchMutation({
    searchText,
    pageNumber: currentPage,
    onFinished: (output) => {
      setMovieOverviews(output.movieOverviews);
      setPageCount(Math.min(Math.ceil(output.moviesTotalCount / 10), 100));
    }
  });

  const handleSearchTextChange = (newText: string) => {
    setSearchText(newText);
    setCurrentPage(1);
    setPageCount(0);
    setMovieOverviews(null);
  };

  useEffect(() => {
    moviesSearchMutation.mutate();
  }, [currentPage, searchText]);

  const handleRowClick = (row: MovieOverview) => {
    movieDetailedRef.current?.setImdbId(row.imdbId);
    modal.current?.open();
  }

  const handleCurrentPageChange = (pageNumber: number) => {
    setMovieOverviews(null);
    setCurrentPage(pageNumber);
  }

  return (
    <main className={`flex flex-col px-5 pt-5 sm:pt-10 ${className}`}>
      <section className='flex self-stretch flex-row justify-end flex-auto flex-grow-0'>
        <RecallSearchTextInput className='w-full sm:w-80 min-w-0' onSearchTextChange={handleSearchTextChange} />
      </section>
      <section className='flex overflow-y-auto flex-grow-0 flex-shrink self-stretch mt-5 min-h-[22.7rem] sm:min-h-[26.813rem]'>
        {movieOverviews?.length ?
            <Table columns={MoviesTableColumns} rows={movieOverviews} onRowClick={handleRowClick} className='self-start'/> :
            (searchText.length && movieOverviews !== null) ? 'No movies found. Try full words.' : movieOverviews === null ? <Spinner/> : null
        }
      </section>
      <span>
        <Pager pagesCount={pageCount} currentPage={currentPage} className='mt-5 mx-auto sm:mx-[10%] lg:mx-[15%]' onCurrentPageChange={handleCurrentPageChange}/>
      </span>
      <Modal ref={modal} title='Movie Details'><MovieDetailed ref={movieDetailedRef} /></Modal>
    </main>
  );
};